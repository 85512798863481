
.sidenav {    position: fixed;
    right: -30px;
    top: 125px;
    width: 236px;
    height: auto;
    border-left: 1px solid #191e3a;}
.sidenav .sidenav-header:after { display: none; }
.sidenav .sidenav-header p {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
    background: #4361ee;
    text-align: center;
    border-radius: 5px;
    padding: 4px;
    letter-spacing: 1px;
    background-image: linear-gradient(to right, #4361ee 0%, #805dca 100%);
}
.sidenav .sidenav-content {
    background-color: transparent;
    display: block;
    border: none;
}
.sidenav .sidenav-content a {
    display: block;
    padding: 3px 0px;
    color: #bfc9d4;
    font-size: 12px;
    padding: 3px 25px;
}
.sidenav .sidenav-content a.active {
    color: #25d5e4;
    font-weight: 700;
    border-left: 1px solid #25d5e4;
}
.sidenav .sidenav-content a:hover {
    color: #25d5e4;
   font-weight: 700;
    border-left: 1px solid #25d5e4;
}
#content > .container {
    display: flex;
    max-width: 58.333333%!important;
    margin-left: 80px;
    margin-left: 2px;
    padding: 0 16px!important;
}
#content > .container > .container { padding: 0; margin: 0; }
@media (max-width: 575px) {
    .sidenav .sidenav-content a {
        padding: 4px 7px;
        margin-right: 0;
        margin-bottom: 10px;
    }
}
@media (max-width: 1199px) {
    .sidenav { display: none; }
    #content > .container {
        max-width: 100%!important;
        margin-left: auto;
    }
    #content > .container > .container {
        max-width: 100%;
    }
}